<template>
      <div class="bg-theme-2 w-full">
        <span class="text-white">This site uses cookies. By continuing to use this website, You are agree to their use. <a class="text-blue underline cursor-pointer">I Understand</a> </span>
      </div>
</template>
<script>
export default {
  name: "Toast"
}
</script>
