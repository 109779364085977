<template>
  <div>
    <div class="navbar-height" id="scrollNavigation" :class="{ 'onChange': !view.underNavigation}">
      <header class="header">
        <a class="header__logo font-poppins-bold text-xl uppercase mt-4 lg:hidden" href="#">
          <slot name="companyName"></slot>
        </a>
        <div class="ham-menu mt-3" @click="changeVisibility()" :class="{ 'active': burgerVisibility }">
          <span :class="white ? '' : 'white-bg'" class="ham-menu__item"></span>
          <span :class="white ? '' : 'white-bg'" class="ham-menu__item"></span>
          <span :class="white ? '' : 'white-bg'" class="ham-menu__item"></span>
        </div>
        <nav class="header__nav-wrap" :class="{ 'open': burgerVisibility }">
          <ul class="header__nav-list" @click="burgerVisibility = false">
            <li class="header__nav-item capitalize cursor-pointer">
              <a class="header__nav-link text-white font-poppins-regular cursor-pointer" @click="goToSlide(1)">Media</a></li>
            <li class="header__nav-item capitalize cursor-pointer">
              <a class="header__nav-link text-white font-poppins-regular cursor-pointer" @click="goToSlide(2)">Tech</a></li>
            <li class="header__nav-item capitalize cursor-pointer">
              <a class="header__nav-link text-white font-poppins-regular cursor-pointer" @click="goToSlide(3)">AI</a></li>
            <li class="header__nav-item capitalize cursor-pointer">
              <a class="header__nav-link text-white font-poppins-regular cursor-pointer" @click="goToSlide(4)">Data</a></li>
            <li class="header__nav-item capitalize cursor-pointer">
              <a class="header__nav-link text-white font-poppins-regular cursor-pointer" @click="goToSlide(5)">Contact</a></li>
          </ul>
        </nav>
      </header>
      <div id="desk-nav" class="desk-nav lg:grid lg:grid-cols-5">
        <div class="mt-8 place-self-center col-start-3">
          <a class="desktop-title hidden lg:block font-poppins-bold text-2xl tracking-widest uppercase mt-4" href="#">
            <slot name="companyNameDesktop"></slot>
          </a>
        </div>
        <div class="col-start-1 place-self-center row-start-1">
          <div class="lg:flex mx-auto justify-center gap-x-3 mt-10 hidden">
            <slot name="socials"></slot>
          </div>
        </div>
        <!--          <div class="talk hidden lg:block absolute">-->
        <!--            <button class="flex contact bg-transparent border border-theme-2 text-theme-2 h-12 p-3 lg:px-8 text-xl font-poppins-regular items-center text-base hover:bg-theme-2 hover:text-white">-->
        <!--              Let's talk-->
        <!--            </button>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  data() {
    return {
      burgerVisibility: false,
      direction: "right",
      effect: "slide",
      duration: 800,
      desktopVisibility: false,
      view: {
        underNavigation: true
      }
    }
  },
  props: ["white"],
  beforeMount() {
    window.addEventListener('scroll', this.handleNavigation)
  },
  methods: {
    changeVisibility() {
      this.burgerVisibility = !this.burgerVisibility;
    },
    openHamburgerDesktop() {
      this.desktopVisibility = !this.desktopVisibility
    },
    handleNavigation() {
      if (window.pageYOffset > 0) {
        if (this.view.underNavigation) this.view.underNavigation = false
      } else {
        if (!this.view.underNavigation) this.view.underNavigation = true
      }
    },
    goToSlide(index){
     return this.$parent.$parent.$refs.swiper.swiper.slideTo(index)
    }
  }
}
</script>
<style lang="scss" scoped>
//.navbar-height {
//  height: 20vh;
//}

@media only screen and (min-width: 1024px) {
  .navbar-height {
    height: 20vh;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    height: 110px;
    z-index: 200;
  }
}

@media only screen and (min-width: 1280px) {
  .navbar-height {
    height: 30vh;
  }
}

@media only screen and (min-width: 960px) {
  .btn {
    display: block;
  }
}

@mixin wide-screen {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 560px) and (max-width: 959px) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: 559px) {
    @content;
  }
}

@mixin hamburger {
  @media screen and (max-width: 959px) {
    @content
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  //font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN",
  //"Hiragino Sans",Meiryo,sans-serif;
}

.header {
  display: flex;
  width: 100%;
  background-color: transparent;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: none;
  @include sp {
    height: 60px;
  }
  @include tab {
    height: 70px;
  }
  @include pc {
    height: 70px;
    display: none;
  }

  &__logo {
    display: inline-block;
    height: auto;
    text-decoration: none;
    color: #343434;
    @include sp {
      margin-left: 30px;
      //font-size: 30px;
      letter-spacing: 0.1em;
    }
    @include tab {
      margin-left: 16px;
      font-size: 24px;
      letter-spacing: 0.1em;
    }
    @include pc {
      display: none;
      //margin-left: 24px;
      //font-size: 26px;
      //letter-spacing: 0.05em;
    }
    @include wide-screen {
      margin-left: 32px;
    }
  }

  &__nav-wrap {
    height: 100%;
    width: max-content;
    margin-left: auto;
    @include hamburger {
      position: fixed;
      left: 0;
      top: 0;
      display: none;
      z-index: 30;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 100%;
    }
    @include pc {
      display: inline-block;
      margin-right: 16px;
      margin-left: auto;
    }

    &.open {
      display: block;
    }
  }

  &__nav-list {
    background: #333333;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    @include hamburger {
      height: 100%;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 30vh;
    }
    @include pc {
      display: flex;
      align-items: center;
    }
  }

  &__nav-item {
    width: max-content;
    @include sp {
      display: block;
      margin: 0 auto;
      margin-bottom: 32px;
      text-align: center;
    }
    @include tab {
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
      text-align: center;
    }
    @include pc {
      display: inline-block;
      margin-right: 24px;
    }
    @include wide-screen {
      margin-right: 40px;
    }
  }

  &__nav-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0.15em;
    color: white;
    @include hamburger {
      font-size: 21px;
      color: #fff;
    }
    @include pc {
      font-size: 18px;
    }
    @include wide-screen {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      bottom: -4px;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      transform: scale(0, 1);
      transform-origin: center top;
      transition-duration: 0.25s;
      @include hamburger {
        background-color: #fff;
      }
      @include pc {
        background-color: white;
      }
    }

    &:hover::after {
      transform: scale(1, 1);
    }
  }

  &__nav-link-desk {
    position: relative;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0.15em;
    color: #343434;
    @include hamburger {
      font-size: 21px;
      color: #343434;
    }
    @include pc {
      font-size: 18px;
    }
    @include wide-screen {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      bottom: -4px;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      transform: scale(0, 1);
      transform-origin: center top;
      transition-duration: 0.25s;
      @include hamburger {
        background-color: #343434;
      }
      @include pc {
        background-color: #343434;
      }
    }

    &:hover::after {
      transform: scale(1, 1);
    }
  }
}

.ham-menu {
  @include hamburger {
    display: inline-block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: 16px;
    transition: all 0.3s;
    z-index: 50;
    position: relative;
    width: 40px;
    height: 40px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    //border-radius: 5px;
    //border: 2px solid white;
    cursor: pointer;
  }
  @include pc {
    display: none;
  }

  &__item {
    @include hamburger {
      display: inline-block;
      box-sizing: border-box;
      transition: all 0.3s;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: #343434;
      border-radius: 5px;
    }

    &:nth-of-type(1) {
      top: 8px;
    }

    &:nth-of-type(2) {
      top: 18px;
    }

    &:nth-of-type(3) {
      bottom: 8px;
    }
  }

  &.active {
    border: none;
  }

  &.active .ham-menu__item:nth-of-type(1) {
    -moz-transform: translateY(10px) rotate(-45deg);
    -webkit-transform: translateY(10px) rotate(-45deg);
    transform: translateY(10px) rotate(-45deg);
    background-color: #fff;
  }

  &.active .ham-menu__item:nth-of-type(2) {
    transition-duration: 0s;
    opacity: 0;
  }

  &.active .ham-menu__item:nth-of-type(3) {
    -moz-transform: translateY(-10px) rotate(45deg);
    -webkit-transform: translateY(-10px) rotate(45deg);
    transform: translateY(-10px) rotate(45deg);
    background-color: #fff;
  }
}

.nav-bar {
  top: 120px;
  position: absolute;
  background: transparent;
  display: none;
  width: 100%;
  text-align: center;
}

.show {
  display: block !important;
}

.nab-bar ul {
  list-style: none;
}

.nav-bar li {
  display: inline-block;
  margin-left: 30px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.nav-bar li:nth-child(1) {
  margin-left: 0;
}

.btn {
  position: absolute;
  top: 50px;
  right: 2rem;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;
}

.btn span {
  background: #fff;
  //border: none;
  border-radius: 5px;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .35s ease;
  cursor: pointer;
}

.btn .middle {
  top: 11px;
}

.btn .bottom {
  top: 22px;
}

.btn.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}

.btn.active .middle {
  opacity: 0;
  background: #fff;
}

.btn.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}

@media only screen and (min-width: 960px) {
  .nav-bar {
    display: block;
  }
  .desktop-title {
    display: block;
  }
  .talk {
    display: block;
    height: 60px;
    top: 35px;
    right: 2.5rem;
    position: absolute;
    background: transparent;
    padding-top: 6px;
  }
  .navbar-height {
    height: 20vh;
  }
}

::v-deep .white-bg {
  background-color: white !important;
}
</style>
