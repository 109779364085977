import state from "./moduleContactState"
import getters from "./moduleContactGetters"
import mutations from "./moduleContactMutaions"
import actions from "./moduleContactActions"
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
