import Vue from 'vue'
import 'swiper/swiper-bundle.css'
import "swiper/swiper.scss";
import App from './App.vue'
import './assets/tailwind.css'
import lottie from 'vue-lottie';
import Scrollspy from 'vue2-scrollspy';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "@/store/store";
// import swiper from "swiper"
import "./vee-validate"
Vue.use(lottie)
Vue.config.productionTip = false
// Vue.use(swiper)
Vue.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
        t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
})
Vue.use(Scrollspy);
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
