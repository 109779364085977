<template>
  <div class="hilo-group grid grid-cols-1 grid-rows-6 lg:block lg:relative">
    <Navbar @white="white" class="nav lg:z-10 lg:absolute lg:w-full">
      <template v-slot:companyName>
        <span class="text-white tracking-spaceEnough">Hilo.<span class="font-poppins-medium capitalize tracking-normal">Group</span></span>
      </template>
      <template v-slot:companyNameDesktop>
        <span class="text-white tracking-spaceEnough">Hilo.<span class="font-poppins-medium capitalize tracking-normal">Group</span></span>
      </template>
      <template v-slot:socials>
        <a href="https://instagram.com/hilo.group?igshid=YmMyMTA2M2Y=" target="_blank" class="cursor-pointer">
          <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="20" height="32"
               viewBox="0 0 16 16"
               style=" fill:#ffffff;">
            <path d="M 2.601563 1 C 1.722656 1 1 1.722656 1 2.601563 L 1 11.402344 C 1 12.277344 1.722656 13 2.601563 13 L 11.402344 13 C 12.277344 13 13 12.277344 13 11.402344 L 13 2.601563 C 13 1.722656 12.277344 1 11.402344 1 Z M 10 2 L 11.402344 2 C 11.734375 2 12 2.265625 12 2.601563 L 12 4 L 10 4 Z M 7 5 C 8.109375 5 9 5.890625 9 7 C 9 8.109375 8.109375 9 7 9 C 5.890625 9 5 8.109375 5 7 C 5 5.890625 5.890625 5 7 5 Z M 2 7 L 4 7 C 4 8.652344 5.347656 10 7 10 C 8.652344 10 10 8.652344 10 7 L 12 7 L 12 11.402344 C 12 11.734375 11.734375 12 11.402344 12 L 2.601563 12 C 2.265625 12 2 11.734375 2 11.402344 Z"></path>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/hilo-people-inc" target="_blank" class="cursor-pointer">
          <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="20" height="30"
               viewBox="0 0 30 30"
               style=" fill:#ffffff;">
            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
          </svg>
        </a>
        <a href="https://www.facebook.com/HiloPeople" target="_blank" class="cursor-pointer">
          <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="20" height="30"
               viewBox="0 0 172 172"
               style=" fill:#ffffff;">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g id="original-icon" fill="#ffffff">
                <path d="M68.8,154.8v-68.8h-22.93333v-22.93333h22.93333v-12.3152c0,-23.31747 11.35773,-33.55147 30.7364,-33.55147c9.28227,0 14.19,0.688 16.512,1.00333v21.93h-13.21533c-8.22733,0 -11.09973,4.34013 -11.09973,13.13507v9.79827h24.10867l-3.27373,22.93333h-20.83493v68.8z"></path>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://www.behance.net/HILOTeam" target="_blank" class="cursor-pointer mt-1.5">
          <svg style=" fill:#ffffff;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/></svg>
        </a>
        <a href="https://dribbble.com/HILOTeam" target="_blank" class="cursor-pointer mt-1.5">
          <svg style=" fill:#ffffff;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z"/></svg>
        </a>
      </template>
    </Navbar>
    <div class="mx-8 row-start-3 lg:flex lg:h-screen lg:top-0 lg:gap-x-5 lg:mx-32 2xl:mx-52 lg:place-self-center lg:absolute lg:items-center lg:w-2/2">
      <div class="text-desk lg:w-1/2">
        <h1 class="text-white text-2xl lg:text-4xl xl:text-5xl font-semibold font-poppins-medium">In the business of creating meaningful businesses.</h1>
      </div>
      <div class="lg:w-1/2">
        <div class="mt-3">
          <h2 class="text-white font-poppins-regular text-base lg:text-2xl xl:text-3xl">A tech company, helping businesses grow sustainably.</h2>
        </div>
        <div class="mt-5">
          <p class="text-white text-xs lg:text-base">
            <span class="font-bold">HILO.Group</span> is about businesses prospering through strong leaders and innovation. We dedicate our time
            to thinking, making and developing meaningful ideas. Ideas need people to become reality and drive change.
          </p>
          <p class="text-white text-xs pt-5 lg:pt-0 lg:text-base">Since 2010, we invest in people that are changing the way we do business. Through technology, creativity and collaboration, we seek to change the way we do business, moving towards sustainable business models.</p>
        </div>
        <div class="mt-5 flex justify-center lg:text-base lg:block">
          <button @click="scrollToSectionOne" class="bg-white font-poppins-medium font-semibold capitalize px-4 h-10 text-xs">Get to know our group</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";

export default {
  name: "HiloGroup",
  data() {
    return {
      white: true
    }
  },
  components: {Navbar},
  methods: {
    scrollToSectionOne() {
      return this.$parent.$refs.swiper.swiper.slideTo(1)
    }
  }
}
</script>
<style lang="scss" scoped>
.hilo-group {
  background-image: url("../assets/images/hilo-group-bg-mobile.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) {
  .hilo-group {
    background-image: url("../assets/images/hilo-group-bg-desktop.png");
  }
}

.nav {
  ::v-deep .header {
    ::v-deep.ham-menu {
      &__item {
        background-color: white !important;
      }
    }
  }
}

//@media only screen and (min-width: 1024px) {
//  .text-desk {
//    padding-bottom: 100%;
//    margin-bottom: -100%;
//    overflow: hidden;
//  }
//}
</style>
