<template>
  <div>
    <div class="swiper swiper-container mySwiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" id="one">
          <HiloGroup />
        </div>
        <div class="swiper-slide" id="two">
          <HiloMedia/>
        </div>
        <div class="swiper-slide" id="three">
          <HiloTech/>
        </div>
        <div class="swiper-slide">
          <HiloAi/>
        </div>
        <div class="swiper-slide">
          <HiloData/>
        </div>
        <div class="swiper-slide">
          <HiloGroupEnd/>
        </div>
        <div class="swiper-slide lg:hidden">
          <Footer class="h-screen"/>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import HiloGroup from "@/components/HiloGroup";
import HiloMedia from "@/components/HiloMedia";
import Swiper, {Mousewheel, Navigation, Pagination} from 'swiper'
import "swiper/swiper.scss"
import HiloTech from "@/components/HiloTech";
import HiloAi from "@/components/HiloAi";
import HiloData from "@/components/HiloData";
import HiloGroupEnd from "@/components/HiloGroupEnd";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    HiloGroupEnd,
    HiloData,
    HiloAi,
    HiloTech,
    HiloMedia,
    HiloGroup,
  },
  data() {
    return {
      // swiperOption: {
      //   height : window.innerHeight,
      //   spaceBetween: 0,
      //   slidesPerView: 1,
      //   touchReleaseOnEdges: true,
      //   onmousewheel: true,
      //   mousewheel: {
      //     releaseOnEdges: true
      //   },
      //   direction: 'vertical',
      //   autoHeight: true,
      //   setWrapperSize: true,
      //   paginationShow: true,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     type: 'bullets',
      //     clickable: true,
      //   },
      // }
    }
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination, Mousewheel],
      direction: "vertical",
      spaceBetween: 0,
      slidesPerView: 1,
      speed: 2000,
      mousewheel: {
        releaseOnEdges: true,
        forceToAxis: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.mySwiper {
  height: 100vh;

  ::v-deep .swiper-pagination {
    & .swiper-pagination-bullet-active {
      background: transparent !important;
      border: 1px white solid !important;
      border-radius: 100% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .mySwiper {
    ::v-deep .swiper-pagination {
      span:nth-child(1) {
        margin-bottom: 3rem;
      }

      span:nth-child(2) {
        margin-bottom: 3rem;
      }

      span:nth-child(3) {
        margin-bottom: 3rem;
      }

      span:nth-child(4) {
        margin-bottom: 3rem;
      }

      span:nth-child(5) {
        margin-bottom: 3rem;
      }

      span:nth-child(6) {
        margin-bottom: 3rem;
      }
    }
  }
}
</style>
